// Device Widths
$iphone-4-landscape: 480px;
$iphone-5: 320px;
$iphone-5-landscape: 568px;
$iphone-6: 375px;
$iphone-6-landscape: 667px;
$iphone-6-plus: 414px;
$iphone-6-plus-landscape: 736px;

$ipad-mini: 768px;
$ipad-mini-landscape: 1024px;

$nexus-6p: 435px;
$nexus-6p-landscape: 773px;

$galaxy-s5: 360px;
$galaxy-s5-landscape: 640px;

@mixin bp($point) {

    $bp-iphone-4-landscape-only: "(width: #{$iphone-4-landscape})";
    $bp-iphone-4-landscape: "(max-width: #{$iphone-4-landscape})";
    $bp-iphone-5: "(max-width: #{$iphone-5})";
    $bp-iphone-5-landscape: "(max-width: #{$iphone-5-landscape})";
    $bp-iphone-6: "(max-width: #{$iphone-6})";
    $bp-iphone-6-landscape: "(max-width: #{$iphone-6-landscape})";
    $bp-iphone-6-plus: "(max-width: #{$iphone-6-plus})";
    $bp-iphone-6-plus-landscape: "(max-width: #{$iphone-6-plus-landscape})";

    $bp-ipad-mini: "(max-width: #{$ipad-mini})";
    $bp-ipad-mini-landscape: "(max-width: #{$ipad-mini-landscape})";

    $bp-nexus-6p: "(max-width: #{$nexus-6p})";
    $bp-nexus-6p-landscape: "(max-width: #{$nexus-6p-landscape})";

    $bp-galaxy-s5: "(max-width: #{$galaxy-s5})";
    $bp-galaxy-s5-landscape: "(max-width: #{$galaxy-s5-landscape})";

    @if $point == iphone-4-landscape-only {
        @media #{$bp-iphone-4-landscape-only} { @content; }
    } @else if $point == iphone-4-landscape {
        @media #{$bp-iphone-4-landscape} { @content; }
    } @else if $point == iphone-5 {
        @media #{$bp-iphone-5} { @content; }
    } @else if $point == iphone-5-landscape {
        @media #{$bp-iphone-5-landscape} { @content; }
    } @else if $point == iphone-6 {
        @media #{$bp-iphone-6} { @content; }
    } @else if $point == iphone-6-landscape {
        @media #{$bp-iphone-6-landscape} { @content; }
    } @else if $point == iphone-6-plus {
        @media #{$bp-iphone-6-plus} { @content; }
    } @else if $point == iphone-6-plus-landscape {
        @media #{$bp-iphone-6-plus-landscape} { @content; }
    } @else if $point == ipad-mini {
        @media #{$bp-ipad-mini} { @content; }
    } @else if $point == ipad-mini-landscape {
        @media #{$bp-ipad-mini-landscape} { @content; }
    } @else if $point == nexus-6p {
        @media #{$bp-nexus-6p} { @content; }
    } @else if $point == nexus-6p-landscape {
        @media #{$bp-nexus-6p-landscape} { @content; }
    } @else if $point == galaxy-s5 {
        @media #{$bp-galaxy-s5} { @content; }
    } @else if $point == galaxy-s5-landscape {
        @media #{$bp-galaxy-s5-landscape} { @content; }
    }

}
