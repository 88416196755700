.sign-in-page {
    form {
        label {
            color: darken($dark-gray, 10%);
            font-size: 16px;
            font-weight: 300;
            height: 30px;
            line-height: 30px;
            margin: 0;
            padding: 0;
            position: relative;
            top: -30px;
            transition: .2s ease-out;
            width: 100%;
            z-index: 0;
        }

        input {
            background: none;
            border: 0;
            border-bottom: 1px solid $dark-gray;
            color: $darker-gray;
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            margin: 0;
            padding: 0;
            position: relative;
            transition: .2s ease-out;
            width: 100%;
            z-index: 1;

            &:focus,
            &:valid {
                border-bottom-color: $brand-color;
                outline: none;

                + label {
                    color: $brand-color;
                    font-size: 12px;
                    top: -50px;
                }
            }
        }

        button {
            background: $brand-color;
            border: 0;
            color: $white;
            display: inline-block;
            float: right;
            font-size: 16px;
            font-weight: 300;
            height: 40px;
            line-height: 40px;
            margin-top: 10px;
            padding: 0 2em;
            text-transform: uppercase;
            transition: .2s ease-out;

            &:hover {
                background: $brand-color-light;
            }

            &:focus {
                outline: none;
            }
        }
    }
}
