.sign-in-page {
    font-family: 'Lato', 'Helvetica', Arial, sans-serif;

    a {
        color: $brand-color;
    }

    a:hover {
        color: $brand-color-light;
    }
}
