.sign-in-page {
    .alert-container {
        background: $alert-container-bg;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .alert {
        align-items: stretch;
        background: $white;
        border: 0;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        left: 50%;
        margin-bottom: 0;
        max-width: 600px;
        min-width: 400px;
        padding: 0;
        position: fixed;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 50%;

        @include bp(ipad-mini) {
            min-width: 450px;
        }

        @include bp(iphone-6-plus) {
            min-width: 400px;
        }

        @include bp(nexus-6p) {
            min-width: 400px;
        }

        @include bp(iphone-6) {
            min-width: 350px;
        }

        @include bp(iphone-5) {
            min-width: 300px;
        }

        @include bp(galaxy-s5) {
            min-width: 300px;
        }

        .icon {
            background: $error-color;
            display: inline-flex;
            padding: 0;
            position: relative;
            width: 25%;
        }

        i {
            align-self: center;
            color: $white;
            font-size: 72px;
            margin: 0 auto;

            @include bp(galaxy-s5) {
                font-size: 48px;
            }
        }

        .message {
            align-self: center;
            display: inline-block;
            padding: 20px;
            width: 75%;
        }

        h2 {
            color: $error-color;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            margin-bottom: 10px;
            margin-top: 0;
            text-transform: uppercase;

            @include bp(iphone-6-plus) {
                font-size: 14px;
            }
        }

        p {
            line-height: 1;
            margin-bottom: 10px;
        }

        button {
            background: none;
            border: 0;
            color: $error-color;
            cursor: pointer;
            float: right;
            font-size: 15px;
            font-weight: 500;
            line-height: 1;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            width: auto;

            @include bp(iphone-6-plus) {
                font-size: 14px;
            }
        }
    }
}
