.popover {
    background: $white;
    border: 0;
    box-shadow: none;
    left: -10px !important;
    top: -3px !important;

    &.right {
        margin-left: 0;

        > .arrow {
            display: none;
        }
    }
}

.popover-title {
    display: none;
}

.popover-content {
    color: $gray;
    position: relative;
    padding: 10px 10px 10px 35px;

    &:before {
        content: '';
        width: 1px;
        height: calc(100% - 37px);
        position: absolute;
        background-color: $light-gray;
        top: 28px;
        left: 17px;
    }
}

.popover-item {
    cursor: pointer;
    font-size: 18px;

    &:hover {
        border: none;
        text-decoration: none;

        .icon-question-sign {
            color: $light-gray;
        }
    }

    .icon-question-sign {
        color: $gray;
        font-weight: 600;
    }
}

.form-group {
    .popover-item {
        display: table-cell;
        height: 34px;
        position: relative;
        vertical-align: middle;
        z-index: 1011;
    }
}

.popover-item-container {
    padding-left: 0;
}
