.sign-in-page {
    .forgot-password {
        h2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 10px;
            margin-top: 0;
        }

        form {
            margin-top: 20px;
        }

        [name="cancel"] {
            background: $light-gray;
            color: $white;
            float: left;
        }
    }
}
