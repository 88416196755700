$brand-color: #3c58bc;
$brand-color-light: #526cc8;
$brand-color-dark: #344ca2;

$second-color: #496f1e;
$second-color-light: #5b8a25;
$second-color-dark: #375417;

$white: #fff;
$gray: #b1b1b1;
$light-gray: lighten($gray, 10%);
$dark-gray: darken($gray, 10%);
$darker-gray: darken($gray, 20%);
$darkest-gray: darken($gray, 30%);

$card-box-shadow-1: rgba(0, 0, 0, .16);
$card-box-shadow-2: rgba(0, 0, 0, .12);

$error-color: #c62828;
$error-color-light: #ffebee;

$alert-container-bg: rgba(0, 0, 0, .5);
