.sign-in-page {
    background-color: $brand-color;
    background-image: linear-gradient(45deg, $brand-color-light, $brand-color);
    display: inline-block;

    .mfc-container,
    .SnapABug_Button {
        display: none;
    }

    .sign-in-bg {
        background-image: url('https://2de9896085aa83228811-329cc4c87f995420b77d2cb67305374f.ssl.cf1.rackcdn.com/baseball.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(5px);
        height: 105%;
        left: -10px;
        opacity: .9;
        position: fixed;
        top: -10px;
        width: 105%;
    }

    .sign-in {
        background-color: $white;
        box-shadow: 0 2px 5px 0 $card-box-shadow-1, 0 2px 10px 0 $card-box-shadow-2;
        left: 50%;
        min-width: 400px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 25%;

        @include bp(iphone-6-plus) {
            min-width: 0;
            width: 75%;
        }

        header {
            background-color: $brand-color;
            color: $white;
            font-size: 24px;
            font-weight: 100;
            padding: 2em 0;
            text-align: center;
            text-transform: uppercase;

            h1 {
                font-size: 24px;
                font-weight: 100;
                line-height: 24px;
                margin: 0;
            }

            span {
                font-weight: 400;
            }
        }

        article {
            display: inline-block;
            height: auto;
            padding: 4em 2em;
        }

        a {
            color: darken($dark-gray, 10%);
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            height: 40px;
            line-height: 40px;
            margin-top: 10px;
            transition: .2s ease-out;

            &:hover {
                color: $brand-color-light;
                text-decoration: none;
            }
        }

        button {
            @include bp(iphone-6-plus) {
                float: none;
                width: 100%;
            }
        }
    }
}
